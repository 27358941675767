import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { qrCodeApi } from "../services/QRcodeApi";

const store = configureStore({
          reducer: {
                    [qrCodeApi.reducerPath]: qrCodeApi.reducer,
          },
          middleware: (getDefaultMiddleware) =>
                    getDefaultMiddleware().concat([
                              qrCodeApi.middleware,
                    ]),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export default store;
setupListeners(store.dispatch);