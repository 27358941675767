import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { toast } from 'react-hot-toast';
import { BeatLoader } from 'react-spinners';
import { useGetQRCodeMutation } from '../app/services/QRcodeApi';
import QRLogoImg from '../assets/qr.png';
import CustomToastMessage from '../shared/CustomToastMessage';
import { TOption, sizeOptions } from '../utils/options';

export default function QrCodeGen() {
          const qrCodeRef = useRef(null);
          const [qrCode, setQrCode] = useState<string>('');

          const [getQRCode, { data, isLoading, isSuccess, error }] = useGetQRCodeMutation();


          const handleCreateQRCode = async (e: SyntheticEvent) => {
                    e.preventDefault();

                    const form = e.target as typeof e.target & {
                              userValue: { value: string };
                              size: { value: string };
                    };

                    const userValue = form.userValue.value;
                    const size = form.size.value;

                    if (userValue === "") {
                              toast.custom(() => (
                                        <CustomToastMessage
                                                  title='Warning'
                                                  subtitle='Please enter your data first'
                                        />
                              ));
                              return;
                    } else if (userValue.length < 20) {
                              toast.custom(() => (
                                        <CustomToastMessage
                                                  title='Warning'
                                                  subtitle='Please enter at least 20 characters'
                                        />
                              ));
                              return;
                    } else if (userValue.length > 1000) {
                              toast.custom(() => (
                                        <CustomToastMessage
                                                  title='Warning'
                                                  subtitle='Please enter less than 1000 characters'
                                        />
                              ));
                              return;
                    }

                    if (userValue !== "" && size !== "") {
                              await getQRCode({ data: userValue, size });
                    }
          }

          useEffect(() => {
                    if (isSuccess) {
                              toast.custom(() => (
                                        <CustomToastMessage
                                                  title='Success'
                                                  subtitle='QR Code Generated Successfully'
                                        />
                              ));
                              window.scrollTo({
                                        top: document.body.scrollHeight || document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                              });
                              setQrCode(data?.data);
                    }

                    if (error) {
                              toast.custom(() => (
                                        <CustomToastMessage
                                                  title='Error'
                                                  subtitle='Something went wrong'
                                        />
                              ));
                    }
          }, [data, isSuccess, error]);

          return (
                    <>
                              <div className='flex flex-col justify-center items-center'>
                                        <div className='w-full pt-10'>
                                                  <div className='flex justify-center items-center pb-5'>
                                                            <img src={QRLogoImg} draggable={false} alt="Logo" className='w-20 h-20 select-none' />
                                                  </div>
                                                  <h1 className='text-center text-3xl font-bold font-lemonMilk'>QR Code Generator</h1>
                                                  <p className='text-center px-3 md:px-0 py-4 text-sm md:text-base font-monospaceTypewriter'>QR Codes allow smartphone users to access your website simply and quickly</p>
                                                  <p className='text-center px-3 md:px-0 text-sm md:text-base font-monospaceTypewriter'>Enter your URL or paste any text below to generate a QR Code and download the QR Image</p>

                                                  <form onSubmit={handleCreateQRCode} className="grid grid-cols-1 gap-3 justify-items-center pt-10 md:pt-5">
                                                            <div className="relative w-11/12 md:w-10/12 max-w-4xl md:p-6">
                                                                      <div className="name border rounded-lg relative mt-5">
                                                                                <div className="name-title absolute -top-4 ml-3 bg-base-100 border rounded-lg p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your data</h3>
                                                                                </div>
                                                                                <textarea
                                                                                          typeof='text'
                                                                                          name="userValue"
                                                                                          className="textarea focus:outline-none w-full bg-transparent mt-3 resize-none h-[15rem]"
                                                                                          placeholder="Enter your data"
                                                                                          aria-labelledby='data'
                                                                                />
                                                                      </div>
                                                                      <div className="name border rounded-lg relative mt-10 w-full">
                                                                                <div className="name-title absolute -top-4 ml-3 bg-base-100 border rounded-lg p-1">
                                                                                          <h3 className="text-xs font-poppins">Select size</h3>
                                                                                </div>
                                                                                <select
                                                                                          name='size'
                                                                                          className="select focus:outline-none w-full mt-2 mb-1"
                                                                                >
                                                                                          {
                                                                                                    sizeOptions.map((size: TOption) => (
                                                                                                              <option key={size.id} value={size.value}>{size.label}</option>
                                                                                                    ))
                                                                                          }
                                                                                </select>
                                                                      </div>
                                                                      <div className="modal-action">
                                                                                <button className={`btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary border-none text-white flex gap-2`} type="submit">
                                                                                          <i className='bx bx-qr-scan'></i>
                                                                                          {isLoading ? 'Generating' : 'Generate'}
                                                                                </button>

                                                                      </div>
                                                            </div>
                                                  </form>
                                        </div>
                                        {
                                                  isLoading ? (
                                                            <div className='flex flex-col justify-center items-center mt-6 h-[30vh]'>
                                                                      <div className='flex flex-col justify-center items-center gap-3'>
                                                                                <img src={QRLogoImg} alt="" className='w-12 h-12' />
                                                                                <BeatLoader color='#D70040' loading={isLoading} size={15} />
                                                                      </div>
                                                            </div>
                                                  ) :
                                                            (
                                                                      <div className='w-full'>
                                                                                <div className="card w-full bg-base-100 flex justify-center items-center">
                                                                                          <div className="card-body">
                                                                                                    <div className="p-3" ref={qrCodeRef}>
                                                                                                              <figure><img src={qrCode} draggable={false} alt="" id='qr-image' className={`${qrCode ? 'border-4 border-gradientPrimary rounded-xl select-none' : 'hidden'}`} /></figure>
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>

                                                                                {
                                                                                          qrCode && (
                                                                                                    <button disabled={isLoading} className='btn btn-sm bg-gradient-to-l from-gradientSecondary to-gradientPrimary border-none text-white mb-20 flex mx-auto' onClick={() => exportComponentAsJPEG(qrCodeRef, {
                                                                                                              fileName: `QRCode-${new Date().getTime()}.png`
                                                                                                    })}>
                                                                                                              <i className='bx bx-download'></i>
                                                                                                              Download
                                                                                                    </button>
                                                                                          )
                                                                                }
                                                                      </div>
                                                            )
                                        }
                              </div>
                              <div className='relative bottom-0'>
                                        <div className='flex flex-col justify-center items-center py-6'>
                                                  <small className='font-semibold'>Copyright &copy; {new Date().getFullYear()} - All rights reserved.</small>
                                                  <small className='text-xs'>Developed by <a href="https://toufiqhasankiron.com" target="_blank" rel="noopener noreferrer"><span className='text-gradientPrimary font-semibold uppercase font-monospaceTypewriter'>Toufiq Hasan Kiron</span></a></small>
                                        </div>
                              </div>
                    </>
          )
}