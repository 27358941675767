import {
          BaseQueryFn,
          createApi,
          FetchArgs,
          fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { BASE_API } from "../../config";

interface CustomError {
          data: {
                    message: string;
                    success: boolean;
          };
          status: number;
}

export const qrCodeApi = createApi({
          reducerPath: "qrCodeApi",
          baseQuery: fetchBaseQuery({ baseUrl: BASE_API }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {
                    data?: unknown;
                    headers?: Record<string, string>;
          }>,
          tagTypes: ["qrCode"],
          endpoints: (builder) => ({
                    createQRCode: builder.query({
                              query: ({ data, size }) => `/qr/create-qr-code?data=${data}&size=${size}`,
                              providesTags: ["qrCode"],
                    }),
                    getQRCode: builder.mutation({
                              query: (body) => ({
                                        url: "/qr/get-qr-code",
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["qrCode"],
                    }),
          }),
});

export const {
          useCreateQRCodeQuery,
          useGetQRCodeMutation,
} = qrCodeApi;