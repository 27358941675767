export type TOption = {
          id: number;
          value: string;
          label: string;
}

export const sizeOptions = [
          {
                    id: 1,
                    value: '100x100',
                    label: '100x100'
          },
          {
                    id: 2,
                    value: '200x200',
                    label: '200x200'
          },
          {
                    id: 3,
                    value: '300x300',
                    label: '300x300'
          },
          {
                    id: 4,
                    value: '400x400',
                    label: '400x400'
          },
          {
                    id: 5,
                    value: '500x500',
                    label: '500x500'
          },
          {
                    id: 6,
                    value: '600x600',
                    label: '600x600'
          },
          {
                    id: 7,
                    value: '700x700',
                    label: '700x700'
          },
          {
                    id: 8,
                    value: '800x800',
                    label: '800x800'
          },
          {
                    id: 9,
                    value: '900x900',
                    label: '900x900'
          },
          {
                    id: 10,
                    value: '1000x1000',
                    label: '1000x1000'
          },
];