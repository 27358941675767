import { MouseEvent, useState } from 'react';
import QrCodeGen from './components/QrCodeGen';
import RightMenuCard from './shared/RightMenuCard';

function App() {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    setMenuVisible(true);
    setMenuPosition({ top: e.clientY, left: e.clientX });
  };

  const handleCloseMenu = () => {
    setMenuVisible(false);
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <QrCodeGen />
      {
        menuVisible && (
          <RightMenuCard top={menuPosition.top} left={menuPosition.left} onClose={handleCloseMenu} />
        )
      }
    </div>
  );
}

export default App;
