import Logo from "../assets/qr.png";

type ToastProps = {
          title: string,
          subtitle: string
};

const CustomToastMessage: React.FC<ToastProps> = ({ title, subtitle }) => {
          return (
                    <div
                              className={`animate-leave max-w-md w-full bg-white shadow-xl rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                              <div className="flex-1 w-0 p-4">
                                        <div className="flex items-start">
                                                  <div className="flex-shrink-0 pt-0.5">
                                                            <img
                                                                      className="h-10 w-10"
                                                                      src={Logo}
                                                                      alt={'QR Code'} />
                                                  </div>
                                                  <div className="ml-3 w-0 flex-1">
                                                            <p className="text-sm font-medium text-gray-900">
                                                                      {title}
                                                            </p>
                                                            <p className="mt-1 text-sm text-gray-500">
                                                                      {subtitle}
                                                            </p>
                                                  </div>
                                        </div>
                              </div>
                    </div>)
};

export default CustomToastMessage;
