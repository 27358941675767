import Logo from '../assets/qr.png';

interface RightClickMenuProps {
          top: number;
          left: number;
          onClose: () => void;
}

export default function RightMenuCard({ top, left, onClose }: RightClickMenuProps) {
          return (
                    <div
                              className="fixed glass rounded-3xl shadow-md z-50 w-full md:w-1/2 lg:w-1/4"
                              style={{ top, left }}
                              onContextMenu={(e) => e.preventDefault()}
                    >
                              <div className="card">
                                        <div className="card-body">
                                                  <div className="flex justify-center mb-5">
                                                            <img src={Logo} alt="Logo" className="w-20 h-20 rounded-lg" />
                                                  </div>
                                                  <p className="text-center mb-5 font-monospaceTypewriter">
                                                            QR Code Generator is developed by <br /> <span className='uppercase font-bold font-lemonMilk'>Toufiq Hasan Kiron</span>
                                                  </p>
                                                  <p className="text-center mb-5 font-monospaceTypewriter">
                                                            If you have any query, please contact <br /> <a href={`mailto:hello@kiron.dev`} className='font-bold sm:hover:underline'>hello@kiron.dev</a>
                                                  </p>
                                                  <p className="text-center font-monospaceTypewriter">
                                                            Thank you for visiting QR Code Generator! <br /> Have a nice day!
                                                  </p>
                                                  <div className='flex justify-end mt-5'>
                                                            <button className="btn btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white font-monospaceTypewriter mr-2" onClick={onClose}>Close</button>
                                                  </div>
                                        </div>
                              </div>

                    </div>
          )
}
